import "../../App.css";

import { AlertColor } from "@mui/material/Alert/Alert";
import { AxiosError } from "axios";
import head from "lodash/head";
import React, { Dispatch } from "react";
import { Route, Routes } from "react-router-dom";

import type QueryClient from "../../api/query/queryClient";
import { fileToSource, toSourceFileId } from "../../api/sources";
import { getTodayISODate, killQueryEngine } from "../../utils/utils";
import { MANUAL_UPLOAD } from "../ClaimsTable/ClaimForm";
import { PostedState, postingStatusSuccess } from "../posting";
import PaymentsDetailsView from "./PaymentsDetailsView";
import PaymentsListView, { PaymentDashboardSettings } from "./PaymentsListView";
import { PaymentsStore, PaymentsStoreAction } from "./store";

interface PaymentsDashboardProps {
  store: PaymentsStore;
  updateStore: Dispatch<PaymentsStoreAction>;
  queryClient: QueryClient;
  loadPractices: () => Promise<void>;
  selectedLocations: PracticeMessage[];
  selectedPractices: PracticeMessage[];
  dashboardSettings: PaymentDashboardSettings;
  saveDashboardSettings: (newSettings: PaymentDashboardSettings) => void;
  updateSnackBar: (snackbar: { severity: AlertColor; message: string }) => void;
  supportedPayers: CredentialsSupportedPayersMessage[];
}

export default function PaymentsDashboard({
  store,
  updateStore,
  queryClient,
  loadPractices,
  selectedLocations,
  selectedPractices,
  dashboardSettings,
  saveDashboardSettings,
  updateSnackBar,
  supportedPayers,
}: PaymentsDashboardProps) {
  const loadPayment = async (paymentId: string) => {
    if (killQueryEngine()) {
      const result = await queryClient.getPaymentDetail(paymentId);
      updateStore({
        type: "SET_PAYMENT",
        payment: result,
        claims: result.Claim,
      });
      return;
    }
    const payment = await queryClient.getPayment(paymentId);
    const claimIds = payment.Claim?.map((claim) => claim.wieldyId) ?? [];
    const claims = claimIds.length
      ? await queryClient.getClaimsProceduresAndPatient(claimIds)
      : [];
    updateStore({
      type: "SET_PAYMENT",
      payment,
      claims,
    });
  };

  const loadPayments = async () => {
    await loadPractices();
    updateStore({
      payments: await queryClient.getPayments(),
      type: "SET_PAYMENTS",
    });
  };

  const resetPayments = () => {
    updateStore({
      type: "RESET_PAYMENTS",
    });
  };

  const resetPayment = () => {
    updateStore({
      type: "RESET_PAYMENT",
    });
  };

  const onSaveProcedure = async (procedure: ProcedureMessage) => {
    const wieldyIdParts = procedure.wieldyId.split(".");
    if (wieldyIdParts.length !== 4) {
      updateSnackBar({
        severity: "error",
        message: `Invalid Wieldy ID for procedure: ${procedure.wieldyId}`,
      });
      return;
    }
    const claimKey = wieldyIdParts.slice(0, 3).join(".");
    const existingClaim = store.claims.find((c) => c.wieldyId === claimKey);
    if (!existingClaim) {
      updateSnackBar({
        severity: "error",
        message: `Claim not found for procedure: ${claimKey}`,
      });
      return;
    }
    const existingProcedure = existingClaim.Procedure.find(
      (p) => p.wieldyId === procedure.wieldyId,
    );
    if (existingProcedure) {
      const deltas = (
        Object.entries(procedure) as [keyof ProcedureMessage, unknown][]
      ).reduce<Partial<ProcedureMessage>>((acc, [key, value]) => {
        // We only need practiceId to update the procedure
        if (key === "practiceId") {
          return acc;
        }
        if (value !== undefined && existingProcedure[key] !== value) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (acc[key] as any) = value;
        }
        return acc;
      }, {} as Partial<ProcedureMessage>);
      if (Object.keys(deltas).length === 0) {
        updateSnackBar({
          severity: "warning",
          message: `Nothing to update`,
        });
        return;
      }
      await queryClient.updateProcedure({
        ...deltas,
        wieldyId: procedure.wieldyId,
        practiceId: procedure.practiceId,
      });
      updateStore({
        type: "UPDATE_PROCEDURE",
        claimKey,
        procedure,
      });
      updateSnackBar({
        severity: "success",
        message: `Successfully edited procedure: ${procedure.wieldyId}`,
      });
      return;
    }
    updateStore({
      type: "CREATE_PROCEDURE",
      claimKey,
      procedure,
    });
    await queryClient.createProcedure(procedure);
    updateSnackBar({
      severity: "success",
      message: `Successfully create procedure: ${procedure.wieldyId}`,
    });
  };

  const updateClaimDeltas = async (
    existingClaim: ClaimWithProcedureAndPatientMessage,
    claim: ClaimMessage,
  ) => {
    const deltas = (
      Object.entries(claim) as [keyof ClaimMessage, unknown][]
    ).reduce<Partial<ClaimMessage>>((acc, [key, value]) => {
      const existingValue =
        existingClaim[key] && key === "payer"
          ? (existingClaim[key] as string).toUpperCase()
          : existingClaim[key];
      if (value !== undefined && existingValue !== value) {
        // Updates on the backend currently only accept string
        acc[key] = value;
      }
      return acc;
    }, {} as Partial<ClaimMessage>);
    if (Object.keys(deltas).length === 0) {
      updateSnackBar({
        severity: "warning",
        message: `Nothing to update`,
      });
      return;
    }
    const wieldyId = await queryClient.updateStringifyClaim({
      ...deltas,
      wieldyId: claim.wieldyId,
      practiceId: claim.practiceId,
    });
    updateStore({
      type: "UPDATE_CLAIM",
      claimToUpdate: {
        ...existingClaim,
        ...claim,
      },
    });
    updateSnackBar({
      severity: "success",
      message: `Successfully edited claim: ${wieldyId}`,
    });
  };

  const createClaim = async (claim: ClaimMessage, file: File | null) => {
    const fileResponse = file
      ? await queryClient.saveFile(claim.practiceId, claim.payer, file)
      : null;
    const wieldyId = await queryClient.createClaim({
      ...claim,
      fileId:
        claim.fileId !== MANUAL_UPLOAD
          ? claim.fileId
          : (fileResponse?.id ?? null),
    });

    const practice = selectedPractices.find(
      (p) => p.wieldyId === claim.practiceId,
    );
    const createdClaim: ClaimWithProcedureAndPatientMessage = {
      ...(claim as ClaimMessage),
      availableSince: getTodayISODate(),
      practice: practice?.name || "",
      practiceDisplayName: practice?.displayName || "",
      Patient: [],
      documents: (() => {
        if (
          store.payment &&
          claim.fileId === head(toSourceFileId(store.payment.documents))
        ) {
          return store.payment.documents;
        }
        return fileResponse ? fileToSource(fileResponse) : null;
      })(),
      Procedure: [],
    };
    updateStore({
      type: "CREATE_CLAIM",
      createdClaim,
    });
    updateSnackBar({
      severity: "success",
      message: `Successfully created claim: ${wieldyId}`,
    });
  };

  const onSaveClaim = async (claim: ClaimMessage, file: File | null) => {
    const existingClaim = store.claims.find(
      (c) => c.wieldyId === claim.wieldyId,
    );
    if (existingClaim) {
      await updateClaimDeltas(existingClaim, claim);
      return;
    }
    await createClaim(claim, file);
  };

  const postToPMS = async (claim: ClaimWithProcedureAndPatientMessage) => {
    try {
      updateStore({
        type: "UPDATE_CLAIM",
        claimToUpdate: {
          ...claim,
          postedState: PostedState[PostedState.POSTING],
        },
      });
      updateSnackBar({
        severity: "info",
        message: "Posting to PMS",
      });
      const postResponse = await queryClient.postToPMS(claim.wieldyId, () => {
        updateSnackBar({
          severity: "info",
          message: "Still posting claim.",
        });
      });
      if (!postingStatusSuccess(postResponse)) {
        updateSnackBar({
          severity: "error",
          message:
            "There was an issue posting to PMS. Please reach out to Wieldy " +
            "support.",
        });
        updateStore({
          type: "UPDATE_CLAIM",
          claimToUpdate: {
            ...claim,
            postedState: postResponse.postedState,
            postedAttempts: postResponse.postedAttempts,
            postedLatestErrorMessage: postResponse.postedLatestErrorMessage,
          },
        });
        return;
      }
      updateStore({
        type: "UPDATE_CLAIM",
        claimToUpdate: {
          ...claim,
          postedState: postResponse.postedState,
          postedDateTime: postResponse.postedDateTime,
          postedAttempts: postResponse.postedAttempts,
        },
      });
      updateSnackBar({
        severity: "success",
        message: "Successfully posted to PMS",
      });
    } catch (error: unknown) {
      if (error instanceof AxiosError && error.response?.data) {
        const responseBody = error.response.data;
        updateSnackBar({
          severity: "error",
          message: responseBody.postedLatestErrorMessage,
        });
        updateStore({
          type: "UPDATE_CLAIM",
          claimToUpdate: {
            ...claim,
            postedState: responseBody.postedState,
            postedAttempts: responseBody.postedAttempts,
            postedLatestErrorMessage: responseBody.postedLatestErrorMessage,
          },
        });
      }
    }
  };

  const updateClaim = async (
    claim: ClaimWithProcedureAndPatientMessage,
    claimWork: ClaimWork,
  ) => {
    updateStore({
      type: "UPDATE_CLAIM",
      claimToUpdate: { ...claim, ...claimWork },
    });
    await queryClient.updateClaim(claimWork);
  };
  return (
    <Routes>
      <Route
        path="/"
        element={
          <PaymentsListView
            payments={store.payments}
            paymentsLoading={!store.loadedPayments}
            load={loadPayments}
            reset={resetPayments}
            selectedLocations={selectedLocations}
            selectedPractices={selectedPractices}
            dashboardSettings={dashboardSettings}
            saveDashboardSettings={saveDashboardSettings}
            updateSnackBar={updateSnackBar}
          />
        }
      />
      <Route
        path="/:paymentId"
        element={
          <PaymentsDetailsView
            claims={store.claims}
            updateClaim={updateClaim}
            postToPMS={postToPMS}
            payment={store.payment}
            loadPayment={loadPayment}
            loadingClaims={!store.loadedClaimsProcedures}
            reset={resetPayment}
            practices={selectedPractices}
            onSaveClaim={onSaveClaim}
            onSaveProcedure={onSaveProcedure}
            supportedPayers={supportedPayers}
          />
        }
      />
    </Routes>
  );
}
