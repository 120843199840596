import "../../App.css";

import { zodResolver } from "@hookform/resolvers/zod";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EditIcon from "@mui/icons-material/Edit";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Button,
  FormControl,
  Menu,
  Select,
  Tooltip,
  useTheme,
  Zoom,
} from "@mui/material";
import { ButtonPropsColorOverrides } from "@mui/material/Button/Button";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import { OverridableStringUnion } from "@mui/types";
import {
  DataGridPro,
  DataGridProProps,
  gridClasses,
  GridColDef,
  GridRenderCellParams,
  GridRowSpacingParams,
  GridSortModel,
  GridValueFormatterParams,
} from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import head from "lodash/head";
import React, { MutableRefObject, useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";

import { toSourceUrls } from "../../api/sources";
import { formatMoney } from "../../formatters";
import { ClaimStatus, ReviewStatus } from "../../status";
import {
  enumToStrings,
  isDevMode,
  isoNow,
  isoToDate,
  snakeToTitle,
  sortAlphabetically,
  sortColumnsByFieldOrder,
  toPascalCase,
  toValueOptions,
} from "../../utils/utils";
import actionLabels, { Action } from "../actions";
import { BankMatchState } from "../bankMatch";
import DownloadSource, { toPDFFileName } from "../PDFViewer/DownloadSource";
import { hasPosted, PostedState } from "../posting";
import { redactText, renderCellRedacted } from "../redact";
import CustomNoRowsOverlay from "../TableNoDataOverlay";
import urgencySelectColor, { Urgency } from "../urgencyColors";
import AddToolbar from "./AddToolbar";
import ClaimDetails from "./ClaimDetails";
import ClaimForm from "./ClaimForm";
import { DEFAULT_COLUMN_VISIBILITY } from "./ClaimsTableColumns";
import {
  AddClaimFormFields,
  claimFormSchemaBase,
  defaultClaimFields,
  editableClaimFields,
} from "./CrudForms.zod";
import { NavigationCell } from "./ui/NavigationCell";

const postingClaimErrors = (
  claim: ClaimWithProcedureAndPatientMessage,
): string[] =>
  [
    {
      value: claim.wieldyPatientName || head(claim.Patient)?.name,
      prettyName: "Patient Name",
    },
    { value: claim.paymentId, prettyName: "Payment ID" },
    {
      value: claim.patientDob || head(claim.Patient)?.dob,
      prettyName: "Patient Date of Birth",
    },
  ]
    .filter((field) => !field.value)
    .map((field) => field.prettyName);

const postOptionButton = (
  buttonText: string,
  buttonColor: OverridableStringUnion<
    | "inherit"
    | "primary"
    | "secondary"
    | "success"
    | "error"
    | "info"
    | "warning",
    ButtonPropsColorOverrides
  >,
  openMenuOptions: boolean,
  handleOpen: (event: React.MouseEvent<HTMLElement>) => void,
  handleClose: () => void,
  anchorEl: HTMLElement | null,
  isPosting: boolean,
  manuallyPost: () => void,
  post: (() => void) | null,
  toolTipOverride?: string,
) => (
  <>
    <Tooltip
      title={toolTipOverride || "Post to PMS"}
      TransitionComponent={Zoom}
      placement="top"
      arrow
    >
      <Button
        data-testid="postButton"
        disabled={isPosting}
        color={buttonColor}
        variant="contained"
        onClick={handleOpen}
        endIcon={<ArrowDropDownIcon />}
        style={{ width: "75%" }}
      >
        {buttonText}
      </Button>
    </Tooltip>
    <Menu
      id="pms-menu"
      aria-labelledby="pms-menu"
      anchorEl={anchorEl}
      open={openMenuOptions}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      sx={{
        marginTop: "1rem",
      }}
    >
      {post ? (
        <MenuItem data-testid="autoPost" onClick={post}>
          Auto Post to PMS
        </MenuItem>
      ) : null}
      <MenuItem data-testid="manualPost" onClick={manuallyPost}>
        Manually Post to PMS
      </MenuItem>
    </Menu>
  </>
);

const missingInfoButton = (
  errors: string[],
  path: string,
  searchParams: URLSearchParams,
) => (
  <Tooltip
    title={
      <div style={{ whiteSpace: "pre-line" }}>
        {`Missing fields: ${errors.join(
          ", ",
        )}. Please update the claim and try again.`}
      </div>
    }
    TransitionComponent={Zoom}
    placement="top"
    arrow
  >
    <Link to={`${path}?${searchParams}`} style={{ width: "75%" }}>
      <Button
        data-testid="missingPostingButton"
        variant="contained"
        color="warning"
        endIcon={<ErrorOutlineIcon />}
      >
        Missing
      </Button>
    </Link>
  </Tooltip>
);

const postButton = (
  disablePosting: boolean,
  props: GridRenderCellParams,
  markManuallyPosted: (
    claimToUpdate: ClaimWithProcedureAndPatientMessage,
    newIsPosted: boolean,
  ) => void,
  postToPMS: (claimToUpdate: ClaimWithProcedureAndPatientMessage) => void,
  searchParams: URLSearchParams,
) => {
  const theme = useTheme();
  const claim: ClaimWithProcedureAndPatientMessage = props.row;
  const errors = postingClaimErrors(claim);
  const pathName = useLocation().pathname;
  const [postingAnchorEl, setPostingAnchorEl] =
    React.useState<null | HTMLElement>(null);
  const openPostingOptions = Boolean(postingAnchorEl);

  const handleClickPostOptions = (event: React.MouseEvent<HTMLElement>) => {
    setPostingAnchorEl(event.currentTarget);
  };
  const handleClosePostOptions = () => {
    setPostingAnchorEl(null);
  };

  if (
    errors.length &&
    claim.postedState === PostedState[PostedState.UNPOSTED]
  ) {
    const path = claim.wieldyPatientId
      ? `/patients/${claim.wieldyPatientId}`
      : "/patients/new-patient";
    const newSearchParams = new URLSearchParams(searchParams);
    newSearchParams.set("redirect", pathName);
    return missingInfoButton(errors, path, newSearchParams);
  }

  const canDocCabPost =
    claim.claimPayerPays !== null &&
    claim.claimPayerPays >= 0 &&
    !!claim.documents?.[0]?.[1]?.length;
  const canLedgerPost =
    claim.claimPayerPays !== null && claim.claimPayerPays > 0;

  if (hasPosted(claim)) {
    return (
      <Button
        data-testid="posted"
        variant="text"
        style={{ width: "75%", color: theme.palette.success.main }}
      >
        {claim.postedState === PostedState[PostedState.MANUALLY_POSTED]
          ? "Manually Posted"
          : "Auto Posted"}
      </Button>
    );
  }

  if (claim.postedState === PostedState[PostedState.FAILURE]) {
    return postOptionButton(
      "Failure",
      "error",
      openPostingOptions,
      handleClickPostOptions,
      handleClosePostOptions,
      postingAnchorEl,
      false,
      () => {
        markManuallyPosted(claim, true);
        handleClosePostOptions();
      },
      null,
      claim.postedLatestErrorMessage
        ? `Failed to Post to PMS. ${claim.postedLatestErrorMessage || ""}. Please post manually.`
        : "Failed to Post to PMS. Please post manually.",
    );
  }
  if (claim.postedState === PostedState[PostedState.RETRY]) {
    return postOptionButton(
      "Retry Post",
      "warning",
      openPostingOptions,
      handleClickPostOptions,
      handleClosePostOptions,
      postingAnchorEl,
      disablePosting,
      () => {
        markManuallyPosted(claim, true);
        handleClosePostOptions();
      },
      canDocCabPost || canLedgerPost
        ? () => {
          postToPMS(claim);
          handleClosePostOptions();
        }
        : null,
      claim.postedLatestErrorMessage || undefined,
    );
  }
  if (!canDocCabPost && canLedgerPost) {
    return postOptionButton(
      "Post Ledger",
      "primary",
      openPostingOptions,
      handleClickPostOptions,
      handleClosePostOptions,
      postingAnchorEl,
      disablePosting,
      () => {
        markManuallyPosted(claim, true);
        handleClosePostOptions();
      },
      () => {
        postToPMS(claim);
        handleClosePostOptions();
      },
      "PDF Missing - Manually Post to Doc Cabinet",
    );
  }

  return postOptionButton(
    claim.postedState === PostedState[PostedState.POSTING] ? "Posting" : "Post",
    "primary",
    openPostingOptions,
    handleClickPostOptions,
    handleClosePostOptions,
    postingAnchorEl,
    disablePosting,
    () => {
      markManuallyPosted(claim, true);
      handleClosePostOptions();
    },
    canDocCabPost || canLedgerPost
      ? () => {
        postToPMS(claim);
        handleClosePostOptions();
      }
      : null,
  );
};

function renderUrgency(
  props: GridRenderCellParams,
  claimToUpdate: (
    claimToUpdate: ClaimWithProcedureAndPatientMessage,
    claimWork: ClaimWork,
  ) => void,
) {
  const { value } = props;
  const urgency = Urgency[value];
  return (
    <FormControl fullWidth>
      <Select
        style={{
          color:
            typeof urgency === "number"
              ? urgencySelectColor.get(urgency)?.color
              : undefined,
          borderRadius: "12px",
        }}
        displayEmpty
        labelId="urgency-select-label"
        id="urgency-simple-select"
        value={urgency ?? -1}
        onChange={(e) => {
          const urgeToSet = Urgency[e.target.value as keyof typeof Urgency];
          const claim = props.row;
          claimToUpdate(claim, {
            wieldyId: claim.wieldyId,
            practiceId: claim.practiceId,
            urgency: urgeToSet?.toString() ?? null,
          });
        }}
        sx={{
          ".MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
        }}
      >
        <MenuItem style={{ color: "#475467" }} value={-1}>
          -
        </MenuItem>
        )
        {Array.from(urgencySelectColor.keys()).map((key) => (
          <MenuItem
            key={key}
            style={{ color: urgencySelectColor.get(key)?.color }}
            value={key}
          >
            {urgencySelectColor.get(key)?.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

function renderAction(
  props: GridRenderCellParams,
  claimToUpdate: (
    claim: ClaimWithProcedureAndPatientMessage,
    claimWork: ClaimWork,
  ) => void,
) {
  const { value } = props;
  const action = Action[value];
  return (
    <FormControl fullWidth>
      <Select
        displayEmpty
        labelId="action-select-label"
        id="action-simple-select"
        value={action ?? -1}
        onChange={(e) => {
          const actionToSet = Action[e.target.value as keyof typeof Action];
          const claim = props.row;
          claimToUpdate(claim, {
            wieldyId: claim.wieldyId,
            practiceId: claim.practiceId,
            action: actionToSet ?? null,
          });
        }}
        sx={{
          ".MuiOutlinedInput-notchedOutline": {
            border: 0,
          },
          borderRadius: "12px",
        }}
      >
        <MenuItem value={-1}>-</MenuItem>
        {Array.from(actionLabels.keys()).map((key) => (
          <MenuItem key={key} value={key}>
            {actionLabels.get(key)?.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

const getColumns = (
  disablePosting: boolean,
  enablePaymentNavigation: boolean,
  payerValueOptions: string[],
  onClaimUpdate: (
    claimToUpdate: ClaimWithProcedureAndPatientMessage,
    claimWork: ClaimWork,
  ) => void,
  markManuallyPosted: (
    claimToUpdate: ClaimWithProcedureAndPatientMessage,
    newIsPosted: boolean,
  ) => void,
  editClaim: (claim: ClaimMessage) => void,
  resetClaim: (claimToUpdate: ClaimWithProcedureAndPatientMessage) => void,
  postToPMS: (claimToUpdate: ClaimWithProcedureAndPatientMessage) => void,
  searchParams: URLSearchParams,
  practiceValueOptions: string[],
  sortColumnOrder?: string[],
) => {
  const theme = useTheme();
  const hasSortColumnOrder = sortColumnOrder?.length;

  let defaultColumns: GridColDef<ClaimWithProcedureAndPatientMessage>[] = [
    {
      flex: 1,
      field: "urgency",
      headerName: "Urgency",
      type: "singleSelect",
      valueOptions: enumToStrings(Urgency).map((urg) => ({
        value: urg.toUpperCase(),
        label: urg,
      })),
      renderCell: (props: GridRenderCellParams) =>
        renderUrgency(props, onClaimUpdate),
      sortComparator: (a: string, b: string) => {
        const urgencyA = Urgency[a as keyof typeof Urgency] ?? -1;
        const urgencyB = Urgency[b as keyof typeof Urgency] ?? -1;
        return urgencyA - urgencyB;
      },
    },
    {
      flex: 0.75,
      field: "practiceDisplayName",
      headerName: "Practice",
      type: "singleSelect",
      valueOptions: practiceValueOptions.length
        ? practiceValueOptions
        : undefined,
    },
    {
      editable: isDevMode(),
      flex: 1.25,
      field: "claimId",
      headerName: "Claim ID",
      renderCell: (params) => {
        const { wieldyPaymentId } = params.row;
        const claimID = params.formattedValue || params.value;
        const isValidPaymentNavigation =
          enablePaymentNavigation && wieldyPaymentId && claimID;

        if (isValidPaymentNavigation) {
          const { paymentId } = params.row;
          const linkPath = `/payments/${wieldyPaymentId}?${searchParams}`;
          const ariaLabel = paymentId
            ? `View Payment ID: ${paymentId}`
            : "View Payment ID";

          return (
            <NavigationCell
              linkPath={linkPath}
              displayText={claimID}
              ariaLabel={ariaLabel}
            />
          );
        }

        return renderCellRedacted(params);
      },
    },
    {
      flex: 0.75,
      field: "payer",
      headerName: "Payer",
      type: "singleSelect",
      valueOptions: payerValueOptions.length ? payerValueOptions : undefined,
      renderCell: renderCellRedacted,
    },
    {
      flex: 0.75,
      field: "paymentId",
      headerName: "Payment ID",
      renderCell: (params: GridRenderCellParams) =>
        params.row.wieldyPaymentId ? (
          <Link to={`/payments/${params.row.wieldyPaymentId}?${searchParams}`}>
            <Button
              data-sr-redact
              type="button"
              aria-label={`View Payment ID: ${params.row.paymentId}`}
              role="button"
              variant="text"
              style={{
                textDecoration: "underline",
                color: theme.palette.primary.main,
              }}
            >
              {params.row.paymentId}
            </Button>
          </Link>
        ) : (
          <div>-</div>
        ),
    },
    {
      flex: 1,
      field: "wieldyPatientName",
      headerName: "Patient Name",
      renderCell: (
        params: GridRenderCellParams<ClaimWithProcedureAndPatientMessage>,
      ) => {
        const { wieldyPatientId } = params.row;
        if (!wieldyPatientId) {
          return renderCellRedacted(params);
        }
        return (
          <Link to={`/patients/${wieldyPatientId}?${searchParams}`}>
            <Button
              data-sr-redact
              type="button"
              aria-label={`View Patient ID: ${wieldyPatientId}`}
              role="button"
              variant="text"
              style={{
                textDecoration: "underline",
                color: theme.palette.primary.main,
              }}
            >
              {redactText(params.value)}
            </Button>
          </Link>
        );
      },
    },
    {
      flex: 1,
      type: "date",
      field: "claimReceivedDate",
      headerName: "Received Date",
      valueGetter: (params: GridRenderCellParams) =>
        params.row.claimReceivedDate
          ? isoToDate(params.row.claimReceivedDate)
          : "",
    },
    {
      flex: 1,
      type: "date",
      field: "claimProcessedDate",
      headerName: "Processed Date",
      valueGetter: (params: GridRenderCellParams) =>
        params.row.claimProcessedDate
          ? isoToDate(params.row.claimProcessedDate)
          : "",
    },
    {
      flex: 0.75,
      field: "claimType",
      headerName: "Claim Type",
    },
    {
      flex: 0.7,
      type: "number",
      field: "claimPriorPaidAmount",
      headerName: "Adjustment Amount",
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value === null ? "-" : formatMoney(params.value),
      renderCell: renderCellRedacted,
    },
    {
      flex: 1,
      type: "date",
      field: "patientDob",
      headerName: "Patient DOB",
      valueGetter: (params: GridRenderCellParams) =>
        params.row.patientDob ? isoToDate(params.row.patientDob) : "",
      renderCell: renderCellRedacted,
    },
    {
      flex: 1,
      field: "sourceId",
      headerName: "Source ID",
    },
    {
      flex: 1,
      field: "wieldySubscriberName",
      headerName: "Subscriber Name",
    },
    {
      flex: 1,
      field: "subscriberId",
      headerName: "Subscriber ID",
    },
    {
      flex: 1,
      field: "relationshipToSubscriber",
      headerName: "Relationship To Subscriber",
    },
    {
      flex: 1,
      field: "lifetimeBenefitUsed",
      headerName: "Lifetime Benefit Used",
    },
    {
      flex: 1,
      field: "claimGroupName",
      headerName: "Claim Group Name",
    },
    {
      flex: 1,
      field: "claimGroupNumber",
      headerName: "Claim Group Number",
    },
    {
      flex: 1,
      field: "patientPayerNetwork",
      headerName: "Payer Network",
    },
    {
      flex: 1,
      field: "claimStatus",
      headerName: "Claim Status",
      type: "singleSelect",
      valueOptions: [
        { value: -1, label: "-" } as { value: string | number; label: string },
      ].concat(
        enumToStrings(ClaimStatus).map((status) => ({
          value: status,
          label: toPascalCase(status).replace("_", " "),
        })),
      ),
      renderCell: (params: GridRenderCellParams) =>
        params.row.claimStatus ? toPascalCase(params.row.claimStatus) : "-",
      valueGetter: (params: GridRenderCellParams) =>
        params.row.claimStatus ?? -1,
    },
    {
      flex: 0.7,
      type: "number",
      field: "claimPayerPays",
      headerName: "Payer Pays",
      valueFormatter: (params: GridValueFormatterParams<number>) =>
        params.value === null ? "-" : formatMoney(params.value),
      renderCell: renderCellRedacted,
    },
    {
      flex: 0.6,
      field: "documents",
      headerName: "PDF",
      disableExport: true,
      renderCell: (props: GridRenderCellParams) => {
        const sourceUrl = head(toSourceUrls(props.value));
        if (!sourceUrl) {
          return null;
        }
        return (
          <DownloadSource
            url={sourceUrl}
            downloadFileName={toPDFFileName(
              props.row.payer,
              props.row.paymentDate,
            )}
          />
        );
      },
      sortComparator: (a: string, b: string) => {
        const aPDF = a?.[0]?.[1]?.[0];
        const bPDF = b?.[0]?.[1]?.[0];
        if (aPDF && bPDF) {
          return aPDF.localeCompare(bPDF);
        }
        if (aPDF) {
          return 1;
        }
        if (bPDF) {
          return -1;
        }
        return 0;
      },
    },
    {
      flex: 1,
      type: "date",
      field: "availableSince",
      headerName: "Available Since",
      valueGetter: (params: GridRenderCellParams) =>
        params.row.availableSince ? isoToDate(params.row.availableSince) : "",
    },
    {
      flex: 1,
      type: "date",
      field: "wieldyClaimDate",
      headerName: "Claim Date",
      valueGetter: (params: GridRenderCellParams) =>
        params.row.wieldyClaimDate ? isoToDate(params.row.wieldyClaimDate) : "",
    },
    {
      flex: 1,
      type: "singleSelect",
      field: "action",
      headerName: "Action",
      valueOptions: [
        { value: -1, label: "-" } as { value: string | number; label: string },
      ].concat(
        enumToStrings(Action).map((action) => ({
          value: action.toString(),
          label: toPascalCase(action).replace("_", " "),
        })),
      ),
      renderCell: (props: GridRenderCellParams) =>
        renderAction(props, onClaimUpdate),
      valueGetter: (params: GridRenderCellParams) => params.row.action ?? -1,
    },
    {
      flex: 2,
      field: "postedState",
      type: "singleSelect",
      headerName: "Posted",
      valueOptions: enumToStrings(PostedState).map((postedState) => ({
        value: postedState.toUpperCase(),
        label: postedState,
      })),
      renderCell: (props: GridRenderCellParams) =>
        postButton(
          disablePosting,
          props,
          markManuallyPosted,
          postToPMS,
          searchParams,
        ),
      sortComparator: (a: string, b: string) => {
        const postedStateA = PostedState[a as keyof typeof PostedState] ?? -1;
        const postedStateB = PostedState[b as keyof typeof PostedState] ?? -1;
        const postedStateANum: number = postedStateA as number;
        const postedStateBNum: number = postedStateB as number;
        return postedStateANum - postedStateBNum;
      },
    },
    {
      flex: 1.5,
      type: "dateTime",
      field: "postedDateTime",
      headerName: "Posted Date",
      renderCell: (params: GridRenderCellParams) =>
        params.row.postedDateTime
          ? `${new Date(params.row.postedDateTime).toLocaleDateString()} ${new Date(
            params.row.postedDateTime,
          ).toLocaleTimeString(navigator.language, {
            hour: "2-digit",
            minute: "2-digit",
          })}`
          : "-",
      valueGetter: (params: GridRenderCellParams) =>
        params.row.postedDateTime ? new Date(params.row.postedDateTime) : null,
    },
    {
      flex: 2,
      field: "bankMatchState",
      type: "singleSelect",
      headerName: "Bank Status",
      valueOptions: enumToStrings(BankMatchState).map((bankMatchState) => ({
        value: bankMatchState.toUpperCase(),
        label: snakeToTitle(bankMatchState),
      })),
    },
    {
      flex: 1,
      field: "reviewStatus",
      type: "singleSelect",
      headerName: "Review Status",
      valueOptions: enumToStrings(ReviewStatus).map((reviewStatus) => ({
        value: reviewStatus.toUpperCase(),
        label: reviewStatus,
      })),
    },
    {
      flex: 1,
      field: "notes",
      headerName: "Notes*",
      renderCell: (params) => {
        const notes = params.row?.notes || "-";
        return <div data-sr-redact>{notes}</div>;
      },
    },
  ];

  if (isDevMode()) {
    defaultColumns.push({
      flex: 1,
      field: "edit",
      headerName: "Edit",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <IconButton
          onClick={() => {
            editClaim(params.row);
          }}
        >
          <EditIcon />
        </IconButton>
      ),
    });
    defaultColumns.push({
      flex: 2,
      field: "",
      headerName: "Reset Claim",
      sortable: false,
      disableColumnMenu: true,
      renderCell: (params: GridRenderCellParams) => (
        <Button
          disabled={
            params.row.postedState === PostedState[PostedState.UNPOSTED]
          }
          type="button"
          variant="contained"
          style={{
            cursor: "pointer",
            width: "100%",
          }}
          onClick={() => {
            resetClaim(params.row);
          }}
        >
          Unpost Claim
        </Button>
      ),
    });
  }

  if (hasSortColumnOrder) {
    defaultColumns = sortColumnsByFieldOrder({
      gridColumns: defaultColumns,
      gridColFieldOrder: sortColumnOrder,
    });
  }
  return defaultColumns;
};

interface ClaimsTableProps {
  claims: ClaimWithProcedureAndPatientMessage[];
  isLoading: boolean;
  onClaimUpdate: (
    claimToUpdate: ClaimWithProcedureAndPatientMessage,
    claimWork: ClaimWork,
  ) => void;
  onPostToPMS: (claimToUpdate: ClaimWithProcedureAndPatientMessage) => void;
  onSaveClaim?: (claim: ClaimMessage, file: File | null) => Promise<void>;
  onSaveProcedure?: (procedure: ProcedureMessage) => Promise<void>;
  enableClaimDetails?: boolean;
  enablePagination?: boolean;
  enablePaymentNavigation?: boolean;
  expandByDefault?: boolean;
  initialState?: GridInitialStatePro;
  apiRef?: MutableRefObject<GridApiPro>;
  initialSortingModel?: GridSortModel;
  excludeByFieldNames?: string[];
  sortColumnOrder?: string[];
  practices: PracticeMessage[];
  supportedPayers?: CredentialsSupportedPayersMessage[];
  payment: PaymentMessage | null;
}

export default function ClaimsTable({
  claims,
  isLoading,
  onClaimUpdate,
  onPostToPMS,
  onSaveClaim,
  onSaveProcedure,
  enableClaimDetails,
  enablePagination,
  enablePaymentNavigation,
  expandByDefault,
  initialState,
  apiRef,
  initialSortingModel,
  excludeByFieldNames,
  sortColumnOrder,
  practices,
  supportedPayers,
  payment,
}: ClaimsTableProps) {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [openClaimModal, setOpenClaimModal] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [claimFileToUpload, setClaimFileToUpload] = React.useState<File | null>(
    null,
  );
  const [fileError, setFileError] = useState<string | null>(null);

  const {
    control: claimControl,
    formState: { errors, isSubmitting, isValid },
    handleSubmit: handleSubmitClaim,
    reset,
  } = useForm<AddClaimFormFields>({
    mode: "onChange",
    reValidateMode: "onChange",
    delayError: 1000,
    resolver: zodResolver(claimFormSchemaBase),
    defaultValues: defaultClaimFields,
  });

  const onEditClaim = (claim: ClaimMessage) => {
    setIsEdit(true);
    const formFields = editableClaimFields.reduce<AddClaimFormFields>(
      (acc, field) => ({
        ...acc,
        [field.name]:
          // We pass payer from FE to BE in titlecase but the BE
          // expects all uppercase.
          field.name === "payer"
            ? claim[field.name as keyof typeof claim]?.toUpperCase()
            : (claim[field.name as keyof typeof claim] ??
              defaultClaimFields[
                field.name as keyof typeof defaultClaimFields
              ]),
      }),
      {} as AddClaimFormFields,
    );
    reset(formFields);
    setOpenClaimModal(true);
  };

  const onClaimModal = (open: boolean) => {
    if (open) {
      reset(defaultClaimFields);
    }
    setIsEdit(!open);
    setOpenClaimModal(open);
  };

  const onClaimFormSubmission = handleSubmitClaim(async (data) => {
    if (onSaveClaim) {
      await onSaveClaim(data as ClaimMessage, claimFileToUpload);
    }
  });

  const claimHasErrors = Object.keys(errors).length > 0;
  const claimIsDisabled = isSubmitting || claimHasErrors || !isValid;

  const getRowSpacing = React.useCallback(
    (params: GridRowSpacingParams) => ({
      top: params.isFirstVisible ? 0 : 8,
      bottom: params.isLastVisible ? 0 : 8,
    }),
    [],
  );

  const markManuallyPosted = (
    claimToUpdate: ClaimWithProcedureAndPatientMessage,
    newIsPosted: boolean,
  ) => {
    onClaimUpdate(claimToUpdate, {
      wieldyId: claimToUpdate.wieldyId,
      practiceId: claimToUpdate.practiceId,
      postedDateTime: newIsPosted ? isoNow() : null,
      postedState: newIsPosted
        ? PostedState[PostedState.MANUALLY_POSTED]
        : PostedState[PostedState.UNPOSTED],
    });
  };

  const resetClaim = (claimToUpdate: ClaimWithProcedureAndPatientMessage) => {
    onClaimUpdate(claimToUpdate, {
      wieldyId: claimToUpdate.wieldyId,
      practiceId: claimToUpdate.practiceId,
      postedDateTime: null,
      postedState: PostedState[PostedState.UNPOSTED],
      postedAttempts: 0,
      postedLatestErrorMessage: null,
    });
  };

  const getDetailPanelContent = React.useCallback<
    NonNullable<DataGridProProps["getDetailPanelContent"]>
  >(
    ({ row }) => (
      <ClaimDetails
        claim={row}
        onClaimDetailUpdate={onClaimUpdate}
        practices={practices}
        onSaveProcedure={onSaveProcedure}
      />
    ),
    [practices, claims],
  );
  const getDetailPanelHeight = React.useCallback(() => "auto", []);

  const disablePosting = claims.some(
    (claim) => claim.postedState === PostedState[PostedState.POSTING],
  );

  const columnVisibility = (excludeByFieldNames || []).reduce(
    (visibilityModel, current) =>
      visibilityModel[current]
        ? { ...visibilityModel, [current]: false }
        : visibilityModel,
    DEFAULT_COLUMN_VISIBILITY,
  );

  return (
    <div
      style={
        claims && claims.length
          ? { width: "100%" }
          : { width: "100%", height: "25rem" }
      }
    >
      <DataGridPro
        loading={isLoading}
        apiRef={apiRef}
        rowHeight={72}
        getRowSpacing={getRowSpacing}
        sx={{
          [`& .${gridClasses.columnHeader}:first-child:focus, & .${gridClasses.cell}:first-child:focus`]:
            {
              borderTopLeftRadius: "12px",
              borderBottomLeftRadius: "12px",
            },
          [`& .${gridClasses.columnHeader}:last-child:focus, & .${gridClasses.cell}:last-child:focus`]:
            {
              borderTopRightRadius: "12px",
              borderBottomRightRadius: "12px",
            },
          [`& .${gridClasses.virtualScrollerRenderZone}`]: {
            width: "100%",
          },
          [`& .${gridClasses["row--detailPanelExpanded"]}`]: {
            borderRadius: "12px 12px 0 0",
            borderColor: "#10182828",
            borderStyle: "solid",
            borderWidth: "1px 1px 0 1px",
            width: "auto",
          },
          [`& .${gridClasses.detailPanel}`]: {
            borderRadius: "0 0 12px 12px",
            borderColor: "#10182828",
            borderStyle: "solid",
            borderWidth: "0 1px 1px 1px",
          },
        }}
        rows={claims}
        columns={getColumns(
          disablePosting,
          !!enablePaymentNavigation,
          toValueOptions(claims, "payer", sortAlphabetically),
          onClaimUpdate,
          markManuallyPosted,
          onEditClaim,
          resetClaim,
          onPostToPMS,
          searchParams,
          toValueOptions(claims, "practiceDisplayName", sortAlphabetically),
          sortColumnOrder,
        )}
        getRowId={(row) => row.wieldyId}
        disableRowSelectionOnClick
        initialState={{
          pagination: enablePagination
            ? { paginationModel: { pageSize: 10 } }
            : undefined,
          sorting: {
            sortModel: initialSortingModel,
          },
          columns: {
            columnVisibilityModel: columnVisibility,
          },
          detailPanel: expandByDefault
            ? { expandedRowIds: claims.map((claim) => claim.wieldyId) }
            : undefined,
          ...(initialState ?? {}),
        }}
        slots={{
          noRowsOverlay: CustomNoRowsOverlay,
          toolbar: AddToolbar,
          detailPanelCollapseIcon: ExpandLessIcon,
          detailPanelExpandIcon: ExpandMoreIcon,
        }}
        slotProps={{
          toolbar: {
            addEntityForm: (
              <ClaimForm
                payment={payment}
                control={claimControl}
                practices={practices}
                supportedPayers={supportedPayers || []}
                enablePDFUpload={!isEdit}
                setClaimFileToUpload={setClaimFileToUpload}
                claimFileToUpload={claimFileToUpload}
                setFileError={setFileError}
                fileError={fileError}
              />
            ),
            handleSubmit: onClaimFormSubmission,
            isDisabled: claimIsDisabled,
            entityName: "Claim",
            enableAddToolBar: !!onSaveClaim,
            setOpenModal: onClaimModal,
            openModal: openClaimModal,
            enableGridToolbar: true,
          },
        }}
        pageSizeOptions={enablePagination ? [10, 20, 40] : undefined}
        pagination={enablePagination}
        getDetailPanelHeight={
          enableClaimDetails ? getDetailPanelHeight : undefined
        }
        getDetailPanelContent={
          enableClaimDetails ? getDetailPanelContent : undefined
        }
      />
    </div>
  );
}

ClaimsTable.defaultProps = {
  enableClaimDetails: false,
  enablePagination: false,
  enablePaymentNavigation: false,
  expandByDefault: false,
  initialSortingModel: [
    { field: "urgency", sort: "desc" },
    { field: "wieldyClaimDate", sort: "desc" },
  ],
  apiRef: undefined,
  initialState: undefined,
  excludeByFieldNames: [],
  sortColumnOrder: [],
  onSaveClaim: undefined,
  onSaveProcedure: undefined,
  supportedPayers: [],
};
