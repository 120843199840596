export enum PostedState {
  UNPOSTED,
  FAILURE,
  AUTO_POSTED,
  MANUALLY_POSTED,
  POSTING,
  RETRY,
}

const successStates = [
  PostedState[PostedState.AUTO_POSTED],
  PostedState[PostedState.MANUALLY_POSTED],
];

export function hasPosted(claim: ClaimWithProcedureMessage): boolean {
  return successStates.includes(claim.postedState);
}

export function postingStatusSuccess(
  postingStatus: ClaimPostingStatus,
): boolean {
  return successStates.includes(postingStatus.postedState);
}
